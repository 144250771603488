import React from 'react'

import { Locations } from '../components/views/locations/locations'
import { LayoutLoggedIn } from '../components/views/layout'

export default function () {
   return (
      <LayoutLoggedIn>
         <Locations />
      </LayoutLoggedIn>
   )
}
